import React, {Component} from 'react';
import {ResponsiveBar} from "@nivo/bar";

import numeral from 'numeral'
import moment from "moment";

class Bar extends Component {

    format (tick) {
        if (this.props.region === 'world')
            return (tick <= 1000000 || tick % 1000000 === 0) ? numeral(tick).format('0a') : numeral(tick).format('0.0a')
        else
            return (tick <= 1000 || tick % 1000 === 0) ? numeral(tick).format('0a') : numeral(tick).format('0.0a')
    }

    getDaysInterval () {

        let width = window.innerWidth;
        let size = this.props.data.length;

        if (width > 1200) return Math.ceil(size/16);
        else if (width <= 992 && width >= 768) return Math.ceil(size/20);
        else if (width < 768 && width >= 400) return Math.ceil(size/12);
        else if (width < 400) return Math.ceil(size/9);
        else return Math.ceil(size/15);
    }

    render() {
            return (
                <ResponsiveBar
                    minValue={0}
                    data={this.props.data}
                    keys={['y']}
                    indexBy="x"
                    indexScale={{ type: 'band', round: false }}
                    margin={{ top: 3, right: 0, bottom: 70, left: 34 }}
                    padding={0.3}
                    colors={'#E8C1A0'}
                    theme={(this.props.dark) ? {axis:{ticks:{text:{fill:'#a1a8b8'}}}} : {}}
                    tooltip={value => (
                        <div style={{backgroundColor:'white', padding:5,border:'1px solid rgba(0,0,0,.2)',position:'absolute',right:0,width:280}}>
                            <b>{moment(value.data.x).format('DD MMM YYYY') + ' : '}</b> {numeral(value.data.y).format('0,0') + ' nouveaux ' + value.data.label}
                        </div>
                    )}
                    borderColor={{ from: 'color', modifiers: [ [ 'darker', 1.6 ] ] }}
                    axisTop={null}
                    axisRight={null}
                    axisBottom={{
                        orient: 'bottom',
                        tickSize: 5,
                        tickPadding: 5,
                        tickRotation: 0,
                        legend: '',
                        legendOffset: 0,
                        legendPosition: 'middle',
                        format: date => moment(date).format('DD/MM'),
                        tickValues: [...Array(Math.round(this.props.data.length / this.getDaysInterval())).keys()].map(i => moment('2020-01-22').add( this.getDaysInterval() *i , 'days').format('YYYY-MM-DD'))
                    }}
                    axisLeft={{
                        orient: 'left',
                        tickSize: 5,
                        tickPadding: 5,
                        tickRotation: 0,
                        legend: '',
                        legendOffset: 0,
                        legendPosition: 'middle',
                        format: tick => (this.format(tick)),
                    }}
                    enableLabel={false}
                    labelSkipWidth={1}
                    labelSkipHeight={1}
                    labelTextColor={{ from: 'color', modifiers: [ [ 'darker', 1.6 ] ] }}
                    animate={true}
                    motionStiffness={90}
                    motionDamping={15}
                    legends={[]}
                />
            )
    }
}

export default Bar;
