import React, {Component} from 'react';
import { Chart } from "react-google-charts";

import config from '../config'

class Map extends Component {

    responsive () {
        let width = window.innerWidth;
        return (width <= 700)
    }

    render() {
        return (
            <Chart
                height={(this.responsive()) ? 380 : 500}
                chartEvents={[
                    /*
                    {
                        eventName: "select",
                        callback: ({ chartWrapper }) => {
                            if (this.props.region === 'world') {
                                const chart = chartWrapper.getChart();
                                const selection = chart.getSelection();
                                if (selection.length === 0) return;
                                const region = this.props.data[selection[0].row + 1];
                                this.props.select(region[0].toLowerCase(), {key: region[2].substring(region[2].lastIndexOf("<span>") + 1, region[2].lastIndexOf("</span>")).replace('span>' , '')});
                            }
                        }
                    }
                     */
                ]}
                chartType="GeoChart"
                data={this.props.data}
                options={{
                    region: (this.props.region !== 'world') ? this.props.region.toUpperCase() : 'world',
                    resolution: (this.props.countriesWithRegions.includes(this.props.region)) ? 'provinces' : '',
                    displayMode: (this.props.countriesWithRegions.includes(this.props.region)) ? 'regions' : '',
                    colorAxis: {
                        colors: (!this.props.dark) ? ['#D2E3FC', '#8AB4F8', '#4285F4', '#1967D2', '#184EA6'] : ['#f37370', '#e84749', '#e84749', '#a61d24', '#791a1f'],
                        values: (this.props.region !== 'world') ? [100,1000,5000,10000,20000] : [1000,10000,50000,100000,200000]
                    },
                    backgroundColor: (this.props.dark) ? '#323741'  : '#fff',
                    datalessRegionColor: (this.props.dark) ? '#616161' : '#eeeeee',
                    defaultColor: '#f5f5f5',
                    legend:'none',
                    tooltip: {isHtml: true}
                }}
                mapsApiKey={config.googleMapApiKey}
            />
        )
    }
}

export default Map;
