import React, {Component} from 'react';
import { Result, Button } from 'antd';

class NotFound extends Component {
    render() {
        return (
            <div className={'center'}>
                <Result
                    status="404"
                    title="404"
                    subTitle="La page à la quelle vous essayez d'accéder n'existe pas"
                    extra={<Button href={'/'} type="primary">Revenir à l'Application</Button>}
                />
            </div>
        );
    }
}

export default NotFound;
