import config from '../config'
import axios from "axios";

const API_TOKEN = config.newsApiKey;
const API_URL = 'https://api.cognitive.microsoft.com/bing/v7.0/news';

let client = axios.create({
    baseURL: API_URL,
    responseType: "json",
    headers : {
        'Ocp-Apim-Subscription-Key': API_TOKEN
    }
});

// TOP NEWS

export function getTopHeadlinesNews (country) {
    return client.get('/search', {
        params: {
            q : 'coronavirus',
            mkt: country
        }
    });
}
