import axios from "axios";
import moment from "moment";

const API_URL = 'https://api.covid19api.com';
const API_URL_2 = 'https://covid19.mathdro.id/api';

let client = axios.create({
    baseURL: API_URL,
    responseType: "json"
});

let client2 = axios.create({
    baseURL: API_URL_2,
    responseType: "json"
});

export function getSummary () {
    return client.get('/summary');
}

export function getCountryEvolution (country) {
    return client.get('/total/country/' + country);
}

export function getCountryEvolutionWithProvince2 (country) {
    return client.get('/live/country/' + country + '/status/confirmed/date/' + moment().subtract(1, 'days').format('YYYY-MM-DD[T00:00:00Z]'));
}

export function getCountryEvolutionWithProvince (country) {
    return client.get('/country/' + country , {
        params: {
            from : moment().subtract(1, 'days').format('YYYY-MM-DD[T00:00:00Z]'),
            to : moment().format('YYYY-MM-DD[T00:00:00Z]')
        }
    })
}

export function getWorldEvolution () {
    return client2.get('/daily');
}