import React, {Component} from 'react';
import {Table} from 'antd';

import numeral from 'numeral'

class RTable extends Component {
    render() {
        return (
            <Table
                scroll={{ x: 460, y:460 }}
                className={((this.props.dark) ? 'table-dark' : '')}
                rowClassName={((this.props.dark) ? 'table-row-dark' : '')}
                bordered
                pagination={ (this.props.data.length > 7 || this.props.data.length === 0) ? {
                    total:this.props.data.length,
                    defaultPageSize:7,
                    size:'small',
                    itemRender: (page, type, originalElement) => (<span className={(this.props.dark) ? 'pagination-dark' : ''}>{originalElement}</span>)
                } : false}
                columns={[
                    {
                        title:(this.props.data.length > 2 && this.props.region !== 'world') ? 'Provinces' : 'Pays',
                        dataIndex:'country',
                        key:'name',
                        render: (text, record) => (
                            <span style={{fontSize:'13px'}}>
                                <span className={'flag-icon flag-icon-' + record.key.toLowerCase()}></span>
                                &nbsp;&nbsp;{text}
                            </span>
                        ),
                        width: 50,
                        className: ((this.props.dark) ? 'table-column-dark' : ''),
                        fixed: 'left'
                    },
                    {
                        title:'Confirmés',
                        dataIndex:'confirmed',
                        key:'confirmed',
                        render: (text) => (<span style={{fontSize:'13px'}}>{ numeral(text).format('0,0') }</span>),
                        width: 40,
                        className: ((this.props.dark) ? 'table-column-dark' : ''),
                        sorter: (a, b) => a.confirmed - b.confirmed,
                        sortDirections: ['ascend', 'descend', 'ascend'],
                        defaultSortOrder : 'descend'
                    },
                    {
                        title:'Décès',
                        dataIndex:'deaths',
                        key:'deaths',
                        render: (text) => numeral(text).format('0,0'),
                        width: 40,
                        className: ((this.props.dark) ? 'table-column-dark' : ''),
                        sorter: (a, b) => a.deaths - b.deaths,
                        sortDirections: ['ascend', 'descend', 'ascend'],
                        defaultSortOrder : 'descend'
                    },
                    {
                        title:'Guéries',
                        dataIndex:'recovered',
                        key:'recovered',
                        render: (text) => numeral(text).format('0,0'),
                        width: 40,
                        className: ((this.props.dark) ? 'table-column-dark' : ''),
                        sorter: (a, b) => a.recovered - b.recovered,
                        sortDirections: ['ascend', 'descend', 'ascend'],
                        defaultSortOrder : 'descend'
                    }
                ]}
                dataSource={((this.props.data.length > 0) ? this.props.data : [] )}
            />
        )
    }
}

export default RTable;