import React, {Component, Fragment} from 'react';
import {ResponsivePie} from "@nivo/pie";

import numeral from 'numeral'

class Pie extends Component {

    responsive () {
        let width = window.innerWidth;
        return (width <= 470)
    }

    render() {
        return (
            <ResponsivePie
                height={490}
                enableRadialLabels={!this.responsive()}
                data={this.props.data}
                margin={ this.responsive() ? {top: 0, right: 20, bottom: 0, left: 20 } : {top: 40, right: 80, bottom: 80, left: 80} }
                padAngle={0.0}
                cornerRadius={3}
                sortByValue={true}
                colors={ (this.props.region === 'world') ? {scheme: (this.props.dark) ? 'dark2' : 'nivo' } : ((this.props.dark) ? ['#D62728', '#1C9E77', '#D95F02'] : ['#F47560', '#66A61F', '#E8A838'] )}
                borderWidth={3}
                borderColor={{ from: 'color', modifiers: [ [ 'darker', 0.2 ] ] }}
                radialLabelsSkipAngle={(this.props.data.length > 3) ? 10 : 5}
                radialLabelsTextXOffset={6}
                radialLabelsTextColor={(this.props.dark) ? '#a1a8b8' : '#333333'}
                radialLabelsLinkOffset={0}
                radialLabelsLinkDiagonalLength={20}
                radialLabelsLinkHorizontalLength={10}
                radialLabelsLinkStrokeWidth={1}
                radialLabelsLinkColor={{ from: 'color' }}
                radialLabel={d => (
                    <Fragment>
                        <tspan x="0" dy="-0.6em">{d.id.split(' ')[0]}</tspan>
                        <tspan x="0" dy="1.2em">{d.id.split(' ')[1]}</tspan>
                    </Fragment>
                )}
                sliceLabelsSkipAngle={this.responsive() ? 22 : 16 }
                sliceLabelsTextColor={(this.props.dark) ? '#fff' : '#333333'}
                sliceLabel={(d) => this.responsive() ? (
                    <Fragment>
                        <tspan x="0" dy="-0.6em">{d.id}</tspan>
                        <tspan x="0" dy="1.7em">{numeral(d.value).format((d.value > 1000) ? '0.0a' : '')}</tspan>
                    </Fragment>
                ) : numeral(d.value).format((d.value > 1000) ? '0.0a' : '')}
                animate={true}
                motionStiffness={90}
                motionDamping={15}
                tooltip={(d) => (
                    <span style={{backgroundColor:'white', padding:5,border:'1px solid rgba(0,0,0,.2)'}}>
                        <span className={'flag-icon flag-icon-' + d.datum.data.label.toLowerCase()}></span>&nbsp;&nbsp;
                        <strong>{d.datum.data.id} : </strong>
                        <span>{numeral(d.datum.data.value).format('0,0') + ' ' + d.datum.data.color}</span>
                    </span>
                )}
            />
        )
    }
}

export default Pie;
