import React, {Component} from 'react';
import { Spin,Empty, Result, Button} from 'antd';

const WithLoader = (PassedComponent) => {
    return class extends Component {
        render() {
            if (this.props.error) {
                    return (
                        <div className={'center'}>
                            <Result
                                status="warning"
                                title={<p className={'message-primary' + ((this.props.dark) ? ' dark' : '') }>Oups, une erreur est survenue. Veuillez réessayer ultérieurement.</p>}
                                subTitle={(<p className={'message-secondary' + ((this.props.dark) ? ' dark' : '') }>Si le problème persiste, merci de nous&nbsp;
                                    <a href="mailto:contact@antonbourtnik.fr">contacter</a>
                                </p>)}
                                extra={
                                    <Button onClick={()=> {window.location.reload()}} type="primary" key="console">
                                        Reesayer
                                    </Button>
                                }
                            />
                        </div>
                    )
            }
            else {
                if (this.props.isLoading) {
                    return (
                        <div className={'center'}>
                            <Spin size="large" />
                        </div>
                    );
                }
                else {
                    if (this.props.data.length === 0) {
                        return (<Empty className={'center'} description={<strong className={'message-primary' + ((this.props.dark) ? ' dark' : '') }>Oups! Désolé nous n'avons pas pu trouver de données pour ce pays</strong>}/>)
                    }
                    else {
                        return (
                            <PassedComponent {...this.props} >
                                {this.props.children}
                            </PassedComponent>
                        )
                    }
                }
            }
        }
    }
};

export default WithLoader;
