import React, {Component} from 'react';

import CountUp from "react-countup";
import {Card as Block, Col, Typography, Popover} from "antd";
import {InfoCircleTwoTone} from '@ant-design/icons';
import numeral from "numeral";

const { Title } = Typography;

class Card extends Component {

    getEvolutionPourcentage (totalCase, newCase) {
        let yesterdayCase = totalCase - newCase;
        return ' (' + numeral((totalCase - yesterdayCase) / yesterdayCase).format('0.00 %') + ')';
    }

    render() {

        const { dark, title, color, data, indicator, indicator_color, style, format } = this.props;

        let counterDuration = 3;
        let counterDelay = 0.5;

        return (
            <Col xs={12} sm={12} md={6} lg={6} xl={6} xxl={6} style={(style || null)}>
                <Block className={'card' + ((dark) ? ' dark' : '')}>
                    <Title className={'indicator-title' + ((dark) ? ' dark' : '')} level={4}>{title}</Title>
                    <div style={{color: color}} className={'indicator' + ((dark) ? ' dark' : '')}>
                        <CountUp
                            start={0}
                            end={data}
                            duration={counterDuration}
                            separator=","
                            delay={counterDelay}
                            formattingFn={(format || null)}
                        />
                    </div>
                    <div style={(indicator !== null) ? {color: indicator_color} : {visibility: 'hidden'}} className={'second-indicator' + ((dark) ? ' dark' : '')}>
                        <CountUp
                            start={0}
                            end={indicator || 0}
                            duration={counterDuration}
                            separator=","
                            prefix="+ "
                            suffix={this.getEvolutionPourcentage(data, indicator)}
                            delay={counterDelay}
                        />
                        <Popover content={(<span>Evolution du nombre de {title.toLowerCase()} en 24h</span>)}>
                            <InfoCircleTwoTone style={{marginLeft:10}}/>
                        </Popover>
                    </div>
                </Block>
            </Col>
        )
    }
}

export default Card;
