import React, {Component} from 'react';
import {Row, Col, Typography, Select, Switch, Layout, Empty, List} from 'antd';
import ReactGA from 'react-ga';

import {getSummary, getWorldEvolution, getCountryEvolution, getCountryEvolutionWithProvince} from './api/covid19'
import {getTopHeadlinesNews} from './api/news'

import moment from 'moment'
import numeral from 'numeral'
import countries from 'i18n-iso-countries'

import 'moment/locale/fr'

import './App.scss';
import 'antd/dist/antd.min.css';
import 'flag-icon-css/css/flag-icon.min.css'

import config from './config'

import Card from './components/Card'
import Pie from './components/Pie'
import Line from './components/Line'
import Bar from './components/Bar'
import Map from './components/Map'
import Table from './components/RTable'

import WithLoader from './hoc/WithLoader'

const PieWithLoader = WithLoader(Pie);
const LineWithLoader = WithLoader(Line);
const BarWithLoader = WithLoader(Bar);
const MapWithLoader = WithLoader(Map);
const TableWithLoader = WithLoader(Table);
const NewsWithLoader = WithLoader(List);

const { Title } = Typography;
const { Footer } = Layout;
const { Option, OptGroup } = Select;

class App extends Component {

  constructor(props) {
    super(props);

      if (config.production){
          ReactGA.initialize('UA-91497130-7');
          ReactGA.pageview(window.location.pathname + window.location.search);
      }

      countries.registerLocale(require("i18n-iso-countries/langs/fr.json"));


    this.region = 'world';

    this.countriesSlug = {
        /*
          'United States of America': 'USA',
          'United Kingdom': 'UK',
          'Iran, Islamic Republic of': 'Iran'
         */
    };

    this.countriesWithRegions = ['cn', 'us', 'ca', 'au'];

    // Countries with provinces but not possible to show on map
    this.countriesSpecial = {
        'gb' : 'United Kingdom',
        'fr' : 'France',
        'nl' : 'Netherlands',
        'dk' : 'Denmark'
    };

    this.countries = [];
    this.countriesSorted = [];
    this.chinaProvinces = {
        'Hubei' : 'Hubei Sheng',
        'Guangdong': 'Guangdong Sheng',
        'Henan' : 'Henan Sheng',
        'Hong Kong' : 'Hong Kong SAR',
        'Heilongjiang' : 'Heilongjiang Sheng',
        'Shanghai' : 'Shanghai Shi',
        'Beijing' : 'Beijing Shi',
        'Sichuan' : 'Sichuan Sheng',
        'Fujian' : 'Fujian Sheng',
        'Hebei' : 'Hebei Sheng',
        'Shanxi' : 'Shanxi Sheng',
        'Inner Mongolia' : 'Nei Mongol',
        'Tianjin' : 'Tianjin Shi',
        'Liaoning' : 'Liaoning Sheng',
        'Gansu' : 'Gansu Sheng',
        'Zhejiang' : 'Zhejiang Sheng',
        'Hunan' : 'Hunan Sheng',
        'Anhui' : 'Anhui Sheng',
        'Jiangxi' : 'Jiangxi Sheng',
        'Shandong' : 'Shandong Sheng',
        'Jiangsu' : 'Jiangsu Sheng',
        'Chongqing' : 'Chongqing Shi',
        'Shaanxi' : 'Shaanxi Sheng',
        'Guangxi' : 'Guangxi',
        'Yunnan' : 'Yunnan Sheng',
        'Hainan' : 'Hainan Sheng',
        'Guizhou' : 'Guizhou Sheng',
        'Jilin' : 'Jilin Sheng',
        'Xinjiang' : 'Xinjiang',
        'Ningxia' : 'Ningxia',
        'Macau' : 'Macao SAR',
        'Qinghai' : 'Qinghai Sheng',
        'Tibet' : 'Tibet',

    };

    this.recentsSearch = [];

    this.date = null;

    this.state = {
      select: 'world',
      switchChart: false,
      switchBar: false,
      darkMode: false,
      data: {
          confirmed: 0,
          deaths: 0,
          recovered: 0,
          newConfirmed: 0,
          newDeaths: 0,
          newRecovered: 0,
      },

      dataPie: [],
      dataTable: [],
      dataChart: [],
      dataBar: [],
      dataMap:[],
      news: [],

      errorPie: false,
      errorTable: false,
      errorChart: false,
      errorBar: false,
      errorMap: false,
      errorNews: false,

      isLoadingPie: true,
      isLoadingTable: true,
      isLoadingChart: true,
      isLoadingBar: true,
      isLoadingMap: true,
      isLoadingNews: true
    };
  }

  componentDidMount() {

    // Set dark Mode
    let darkMode = localStorage.getItem('darkMode');
    if (darkMode !== null && darkMode === 'true')
        this.setState({darkMode:true});

    // Load Recent Search

    let recents = localStorage.getItem('recent');
    this.recentsSearch = (recents !== null) ? JSON.parse(recents) : [];

    // Load Data for World

    this.loadWorld();
  }

  mapTooltip(country,countryCode,confirmed,deaths,recovered) {
      return '<div style="width: 150px;">' +
                '<div style="text-align: left;margin-bottom: 10px">' +
                '<span class="flag-icon flag-icon-' + countryCode.toLowerCase() + '"></span> &nbsp;&nbsp;' +
                '<span>' + country + '</span>' +
                '</div>' +
                '<div style="text-align: left">' + numeral(confirmed).format('0,0')  + '<strong> confirmés</strong></div>' +
                '<div style="text-align: left">' + numeral(deaths).format('0,0')  + '<strong> décès</strong></div>' +
                '<div style="text-align: left">' + numeral(recovered).format('0,0')  + '<strong> guéries</strong></div>' +
            '</div>'
  }

  loadWorld () {

      this.setState({
          isLoadingPie: true,
          isLoadingTable: true,
          isLoadingChart: true,
          isLoadingBar: true,
          isLoadingMap: true,
          isLoadingNews: true,
          select:'world',
          errorPie: false,
          errorTable: false,
          errorChart: false,
          errorBar: false,
          errorMap: false,
          errorNews: false
      });


      getSummary().then(response => {

          let data = response.data;

          this.countries = data.Countries.filter(country => country.TotalConfirmed > 0);
          this.countries.forEach(function(country) {
              country.Country = countries.getName(country.CountryCode, "fr")
          });
          this.countries = this.countries.sort(function (a, b) {
              return a.Country.localeCompare(b.Country, 'fr')
          });

          this.countriesSorted = [...this.countries].sort(function (a, b) {
              return (a.TotalConfirmed > b.TotalConfirmed) ? -1 : ((b.TotalConfirmed > a.TotalConfirmed) ? 1 : 0)
          });

          this.date = data.Date;

          this.setState({
              data:{
                  confirmed:  data.Global.TotalConfirmed,
                  deaths:     data.Global.TotalDeaths,
                  recovered:  data.Global.TotalRecovered,
                  newConfirmed: data.Global.NewConfirmed,
                  newDeaths: data.Global.NewDeaths,
                  newRecovered: data.Global.NewRecovered,
              },
              dataPie:this.countriesSorted.slice(0,10).map(country => ({
                  id: (Object.keys(this.countriesSlug).includes(country.Country)) ? this.countriesSlug[country.Country] : country.Country,
                  label: country.CountryCode,
                  value: country.TotalConfirmed,
                  color: 'confirmés'
              })),
              dataMap:[
                  ['Country', 'Confirmed', {'type': 'string', 'role': 'tooltip', 'p': {'html': true}}],
                  ...data.Countries.map(
                      country => [
                          country.CountryCode,
                          country.TotalConfirmed,
                          this.mapTooltip(country.Country, country.CountryCode, country.TotalConfirmed, country.TotalDeaths, country.TotalRecovered)
                      ]
                  )
              ],
              dataTable: this.countriesSorted.map(country => ({
                  key: country.CountryCode,
                  country: (Object.keys(this.countriesSlug).includes(country.Country)) ? this.countriesSlug[country.Country] : country.Country,
                  confirmed: country.TotalConfirmed,
                  deaths: country.TotalDeaths,
                  recovered:  country.TotalRecovered
              })),
              isLoadingPie: false,
              isLoadingMap: false,
              isLoadingTable: false
          });

      }).catch(error => {
          console.error(error);
          this.setState({
              errorPie: true,
              errorMap: true,
              errorTable: true,
              isLoadingPie: false,
              isLoadingMap: false,
              isLoadingTable: false
          });
      });

      getWorldEvolution().then(response => {

          let data = response.data;

          this.setState({
              dataChart:data.map(day => ({
                  x: moment(day.reportDate).format('YYYY-MM-DD'),
                  y: day.totalConfirmed,
                  label: 'confirmés'
              })),
              dataBar:data.map(day => ({
                  x: moment(day.reportDate).format('YYYY-MM-DD'),
                  y: day.deltaConfirmedDetail.total,
                  label: 'confirmés'
              })),
              isLoadingChart: false,
              isLoadingBar: false
          });

      }).catch(error => {
          console.error(error);
          this.setState({
              isLoadingChart: false,
              isLoadingBar: false,
              errorChart: true,
              errorBar: true
          });
      });

      // Get News

      getTopHeadlinesNews('fr-FR').then(response => {

          let data = response.data.value;

          this.setState({
              news: data.map(article => ({
                  source: article.provider[0].name,
                  title: article.name,
                  url: article.url,
                  date: article.datePublished
              })),
              isLoadingNews: false
          });

      }).catch(error => {
          console.error(error);
          this.setState({
              errorNews: true,
              isLoadingNews: false
          });
      });
  }

  toggleMode = () => {
      this.setState(function (state, props) {
          return {darkMode: !state.darkMode}
      }, () => {
          localStorage.setItem('darkMode', this.state.darkMode);
      });
  };

  barFormat(data, label = 'Confirmed') {

      let formatted_data = [];
      let last_correct_value = 0;

      data.forEach((day, index) => {

          let y = 0;

          if (index === 0){
              y = day[label];
              last_correct_value = day[label];
          }
          else {
              let delta = data[index][label] - data[index - 1][label];

              if (delta <= 0){
                  y = last_correct_value;
              }
              else {
                  last_correct_value = delta;
                  y = delta;
              }

          }

          formatted_data.push({
              x: moment(day.Date).format('YYYY-MM-DD'),
              y: y,
              label: 'confirmés'
          })
      });

      return formatted_data;

  }

  select = (value, option)  =>  {

      this.setState({
          isLoadingPie: true,
          isLoadingChart: true,
          isLoadingMap: true,
          isLoadingTable: true,

          errorPie: false,
          errorChart: false,
          errorMap: false,
          errorTable: false,

          select: option.key,
          switchChart: false,
          switchBar: false,
      });

      this.region = value;

      if (value !== 'world') {

          // Add recent

          let recent = localStorage.getItem('recent');

          if (recent === null){
              localStorage.setItem('recent', JSON.stringify([{Country: option.key, CountryCode: value}]));
              this.recentsSearch = JSON.parse(localStorage.getItem('recent'));
          }
          else {
              let searchs = JSON.parse(localStorage.getItem('recent'));
              let max_number = 5;

              // Test if countries already in recents searchs
              if(!searchs.some(search => search.CountryCode === value)){
                  searchs.unshift({Country: option.key, CountryCode: value});
                  if (searchs.length > max_number) searchs.pop();
              }
              else {
                  let index = searchs.findIndex(search => search.CountryCode === value);
                  searchs.unshift(searchs[index]); // Add
                  searchs.splice(index + 1, 1) // Remove
              }

              localStorage.setItem('recent', JSON.stringify(searchs));
              this.recentsSearch = searchs;
          }

          getCountryEvolution(value).then(response => {

              let data = response.data;

              this.setState({
                  dataChart:data.map(day => ({
                      x: moment(day.Date).format('YYYY-MM-DD'),
                      y: day.Confirmed,
                      label: 'confirmés'
                  })),
                  dataBar: this.barFormat(data),
                  isLoadingChart: false,
                  isLoadingBar: false
              });
          }).catch(error => {
              console.error(error);
              this.setState({
                  errorChart: true,
                  errorBar: true,
                  isLoadingChart: false,
                  isLoadingBar: false
              });
          });

          getSummary().then(response => {

              let data = response.data.Countries.filter(country => country.CountryCode === value.toUpperCase())[0];

              this.setState({
                  data: {
                      confirmed: data.TotalConfirmed,
                      deaths: data.TotalDeaths,
                      recovered: data.TotalRecovered,
                      newConfirmed: data.NewConfirmed,
                      newDeaths: data.NewDeaths,
                      newRecovered: data.NewRecovered
                  }
              });
          }).catch(error => {console.error(error)});

          getCountryEvolutionWithProvince(value).then(response => {

              let data = response.data;

              if (data.length > 0) {
                  let multiple = (data.length > 1);

                  let confirmed = (multiple) ? [...data].reduce((a, b) => a + (b.Confirmed || 0), 0) : data[0].Confirmed;
                  let deaths = (multiple) ? [...data].reduce((a, b) => a + (b['Deaths'] || 0), 0) : data[0].Deaths;
                  let recovered = (multiple) ? [...data].reduce((a, b) => a + (b['Recovered'] || 0), 0): data[0].Recovered;
                  //let active = (multiple) ? [...data].reduce((a, b) => a + (b['Active'] || 0), 0) : data[0].Active;

                  let sortedProvince = [...data].sort(function (a, b) {
                      return (a.Confirmed > b.Confirmed) ? -1 : ((b.Confirmed > a.Confirmed) ? 1 : 0)
                  });

                  // DATA FOR MAP

                  let dataMap = null;


                  // China

                  if (this.region === 'cn') {
                      dataMap = [...data].map(country => [
                          this.chinaProvinces[country.Province],
                          country.Confirmed,
                          this.mapTooltip(country.Province, 'cn', country.Confirmed, country.Deaths, country.Recovered)
                      ]);

                  }

                  // United States of America

                  else if (this.region === 'us') {

                      let usa = [];

                      [...data].forEach(function (province) {

                          let findIndex = usa.findIndex(element => element.Province === province.Province);

                          if (findIndex !== -1){
                              usa[findIndex].Confirmed += province.Confirmed;
                              usa[findIndex].Deaths += province.Deaths;
                              usa[findIndex].Recovered += province.Recovered;
                          }
                          else {
                              usa.push({
                                  Province: province.Province,
                                  Confirmed: province.Confirmed,
                                  Deaths : province.Deaths,
                                  Recovered : province.Recovered,
                                  CountryCode : province.CountryCode
                              });
                          }
                      });

                      sortedProvince = usa.sort(function (a, b) {
                          return (a.Confirmed > b.Confirmed) ? -1 : ((b.Confirmed > a.Confirmed) ? 1 : 0)
                      });

                      dataMap = [...usa].map(country => [
                          country.Province,
                          country.Confirmed,
                          this.mapTooltip(country.Province, 'us', country.Confirmed, country.Deaths, country.Recovered)
                      ]);
                  }

                  else {
                      dataMap = (Object.keys(this.countriesSpecial).includes(this.region)) ?
                          [[this.countriesSpecial[this.region] , sortedProvince[0]['Confirmed'], this.mapTooltip(countries.getName(this.region, 'fr'), this.region, sortedProvince[0]['Confirmed'], sortedProvince[0]['Deaths'], sortedProvince[0]['Recovered'])]] :
                          [...data].map(country => [
                              (this.countriesWithRegions.includes(this.region) ? country.Province : country.Country),
                              country.Confirmed,
                              this.mapTooltip(countries.getName(country.CountryCode, 'fr'), country.CountryCode, country.Confirmed, country.Deaths, country.Recovered)
                          ]);
                  }

                  dataMap.unshift([(this.countriesWithRegions.includes(this.region) ? 'Province' : 'Country'), 'Confirmed', {'type': 'string', 'role': 'tooltip', 'p': {'html': true}}]);

                  // END DATA FOR MAP

                  this.setState({
                      dataPie:[
                          {id: 'Décès', label: value, value: deaths, color: ''},
                          {id: 'Guéries', label: value, value: recovered, color: ''},
                          {id: 'Actifs', label: value, value: (confirmed - deaths - recovered), color: ''}
                      ],
                      dataMap:dataMap,
                      dataTable: sortedProvince.map(country => ({
                          key: country.CountryCode,
                          country: (country.Province) ? country.Province : countries.getName(country.CountryCode, 'fr'),
                          confirmed: country.Confirmed,
                          deaths: country.Deaths,
                          recovered:  country.Recovered
                      })),
                      isLoadingPie: false,
                      isLoadingMap: false,
                      isLoadingTable: false
                  });
              }
              else {
                  this.setState({
                      isLoadingPie: false,
                      isLoadingMap: false,
                      isLoadingTable: false,

                      errorPie: true,
                      errorMap: true,
                      errorTable: true
                  });
              }
          }).catch(error => {
              console.error(error);
              this.setState({
                  isLoadingPie: false,
                  isLoadingMap: false,
                  isLoadingTable: false,

                  errorPie: true,
                  errorMap: true,
                  errorTable: true
              });
          });
      }
      else
          this.loadWorld()
  };

  toggleStatusPie = (checked) => {

      this.setState({isLoadingPie: true, select:'world', errorPie:false});

      let status = (checked) ? 'TotalDeaths' : 'TotalConfirmed';

          getSummary().then(response => {

              let data = response.data;

              this.setState({
                  dataPie:data.Countries.filter(country => country[status] > 0).sort(function (a, b) {
                      return (a[status] > b[status]) ? -1 : ((b[status] > a[status]) ? 1 : 0)
                  }).slice(0,10).map(country => ({
                      id: (Object.keys(this.countriesSlug).includes(country.Country)) ? this.countriesSlug[country.Country] : countries.getName(country.CountryCode, 'fr'),
                      label: country.CountryCode,
                      value: country[status],
                      color: (checked) ? 'décès' : 'confirmés'
                  })),
                  isLoadingPie: false,
              });

          }).catch(error => {
              console.error(error);
              this.setState({
                  isLoadingPie: false,
                  errorPie: true
              });
          });
  };

  toggleStatusLine = (checked) => {

        this.setState({isLoadingChart:true, switchChart:checked, errorChart:false});

        if (this.state.select === 'world') {
            getWorldEvolution().then(response => {

                let data = response.data;

                this.setState({
                    dataChart:data.map(day => ({
                        x: moment(day.reportDate).format('YYYY-MM-DD'),
                        y: (checked) ? day.deaths.total : day.totalConfirmed,
                        label: (checked) ? 'décès' : 'confirmés'
                    })),
                    isLoadingChart: false
                });

            }).catch(error => {
                console.error(error);
                this.setState({isLoadingChart: false, errorChart: true});
            });
        } else {
            getCountryEvolution(this.region).then(response => {

                let data = response.data;

                this.setState({
                    dataChart:data.map(day => ({
                        x: moment(day.Date).format('YYYY-MM-DD'),
                        y: (checked) ? day.Deaths : day.Confirmed,
                        label: (checked) ? 'décès' : 'confirmés'
                    })),
                    isLoadingChart: false
                });
            }).catch(error => {
                console.error(error);
                this.setState({
                    errorChart: true,
                    isLoadingChart: false
                });
            });
        }
    };

  toggleStatusBar = (checked) => {

        this.setState({isLoadingBar: true, switchBar:checked, errorBar: false});

        if (this.state.select === 'world') {
            getWorldEvolution().then(response => {

                let data = response.data;

                this.setState({
                    dataBar:data.map( (day, index) => ({
                        x: moment(day.reportDate).format('YYYY-MM-DD'),
                        y: (checked) ? ((index === 0) ? day.deaths.total : data[index].deaths.total - data[index - 1].deaths.total ) : day.deltaConfirmedDetail.total,
                        label: (checked) ? 'décès' : 'confirmés'
                    })),
                    isLoadingBar: false
                });

            }).catch(error => {
                console.error(error);
                this.setState({isLoadingBar: false, errorBar: true });
            });
        } else {
            getCountryEvolution(this.region).then(response => {

                let data = response.data;

                this.setState({
                    dataBar: this.barFormat(data, (checked) ? 'Deaths' : 'Confirmed'),
                    isLoadingBar: false
                });
            }).catch(error => {
                console.error(error);
                this.setState({
                    errorBar: true,
                    isLoadingBar: false
                });
            });
        }
    };

  render() {
          return (
              <div className={'container' + ((this.state.darkMode) ? ' dark' : '')}>
                  <Row gutter={[0, 24]} align="middle" className={'header' + ((this.state.darkMode) ? ' dark' : '')} style={{marginBottom:20}}>
                      <Col xs={24} sm={24} md={9} lg={7} xl={6} xxl={4} style={{position:'relative'}}>
                          <div className={'text' + ((this.state.darkMode) ? ' dark' : '')}>
                              <span style={{fontWeight:'bold'}}>Mise à jour : </span>
                              <span>{moment(this.date).format('DD MMMM YYYY [à] HH:mm:ss')}</span>
                          </div>
                      </Col>
                      <Col xs={{ span: 17, offset: 0 }} sm={{ span: 20, offset: 0 }} md={{ span: 7, offset: 6 }} lg={{ span: 5, offset: 9 }} xl={{ span: 5, offset: 11 }} xxl={{span: 3, offset: 15}}>
                          <Select
                              className={'select' + ((this.state.darkMode) ? ' dark' : '')}
                              showSearch
                              optionFilterProp="children"
                              defaultValue="world"
                              value={this.region}
                              style={{ width: 200 }}
                              size={'default'}
                              placeholder="Selectionner un pays"
                              filterOption={(input, option) =>
                                  (option.key.includes('SELECT') || option.key.substr(option.key.length - 1) === '2') ? null : option.key.toLowerCase().normalize("NFD").replace(/[\u0300-\u036f]/g, "").indexOf(input.toLowerCase()) >= 0
                              }
                              onChange={this.select}
                              notFoundContent={(
                                    <div>
                                        <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description={<strong>Aucun pays trouvé</strong>}/>
                                    </div>
                              )}
                          >
                              <OptGroup label="Global">
                                  <Option key={'world'} value="world">
                                      <img src="img/world.png" alt="World icon"/>
                                      &nbsp;&nbsp;Monde
                                  </Option>
                              </OptGroup>
                              {this.recentsSearch.length > 0 ?
                                  <OptGroup label="Récent">
                                      {this.recentsSearch.map(country => {
                                          return (<Option key={countries.getName(country.CountryCode, 'fr') + '2'} value={country.CountryCode.toLowerCase()}>
                                              <span className={'flag-icon flag-icon-' + country.CountryCode.toLowerCase()}></span>
                                              &nbsp;&nbsp;{country.Country}
                                          </Option>)
                                      })}
                                  </OptGroup> : null
                              }
                              <OptGroup label="Pays">
                                  {this.countries.map(country => {
                                      return (<Option key={countries.getName(country.CountryCode, 'fr')} value={country.CountryCode.toLowerCase()}>
                                          <span className={'flag-icon flag-icon-' + country.CountryCode.toLowerCase()}></span>
                                          &nbsp;&nbsp;{country.Country}
                                      </Option>)
                                  })}
                              </OptGroup>
                          </Select>
                      </Col>
                      <Col xs={7} sm={4} md={2} lg={3} xl={2} xxl={2} style={{textAlign:'right'}}>
                          <Switch
                              defaultChecked={false}
                              checked={(this.state.darkMode) ? true  : false}
                              checkedChildren="Sombre"
                              unCheckedChildren="Clair"
                              onChange={() => {this.toggleMode()}}
                          />
                      </Col>
                  </Row>
                  <Row gutter={[{ xs: 0, sm: 12, md: 12, lg: 12 }, 12]}>
                      <Card
                          dark={this.state.darkMode}
                          title={'Confirmés'}
                          color={'#857CF9'}
                          data={this.state.data.confirmed}
                          indicator={this.state.data.newConfirmed}
                          indicator_color={'#9d96fa'}
                      />
                      <Card
                          dark={this.state.darkMode}
                          title={'Décès'}
                          color={'#CF1221'}
                          data={this.state.data.deaths}
                          indicator={this.state.data.newDeaths}
                          indicator_color={'#ec2d3c'}
                          style={{paddingLeft: 10}}
                      />
                      <Card
                          dark={this.state.darkMode}
                          title={'Guéries'}
                          color={'#3F8600'}
                          data={this.state.data.recovered}
                          indicator={this.state.data.newRecovered}
                          indicator_color={'#52af00'}
                      />
                      <Card
                          dark={this.state.darkMode}
                          title={this.state.select === 'world' ? 'Pays' : 'Position'}
                          color={'#648DAE'}
                          data={(this.region !== 'world') ? this.countriesSorted.findIndex((country) => country.CountryCode.toLowerCase() === this.region) + 1 : this.countries.length}
                          indicator={null}
                          indicator_color={'#52af00'}
                          style={{paddingLeft: 10}}
                          format={(n) => {return (this.region !== 'world') ? ((n === 1) ? n + 'er' : n + 'ème') : n.toString() }}
                      />
                  </Row>
                  <Row gutter={[{ xs: 0, sm: 12, md: 12, lg: 12 }, 12]}>
                      <Col xs={24} sm={24} md={24} lg={11} xl={10} xxl={10}>
                          <div className={'block' + ((this.state.darkMode) ? ' dark' : '')}>
                              <Row justify={'space-between'}>
                                  <Col xs={(this.state.select === 'world') ? 18 : 24} sm={(this.state.select === 'world') ? 19 : 24} md={(this.state.select === 'world') ? 20 : 24} lg={(this.state.select === 'world') ? 18 : 24} xl={(this.state.select === 'world') ? 19 : 24} xxl={(this.state.select === 'world') ? 20 : 24}>
                                      <Title className={'title' + ((this.state.darkMode) ? ' dark' : '')} level={4}>{this.state.select === 'world' ? 'Top 10 Des Pays Infectés' : this.state.select + ' : Repartition des Infectées'}</Title>
                                  </Col>
                                  { (this.state.select === 'world') ?
                                  <Col xs={6} sm={5} md={4} lg={6} xl={5} xxl={4}>
                                      <Switch
                                          defaultChecked={false}
                                          checkedChildren="Décès"
                                          unCheckedChildren="Confirmés"
                                          loading={this.state.isLoadingPie}
                                          onChange={(checked) => {this.toggleStatusPie(checked)}}
                                      />
                                  </Col> : null
                                  }
                              </Row>
                              <PieWithLoader
                                  data={this.state.dataPie}
                                  dark={this.state.darkMode}
                                  isLoading={this.state.isLoadingPie}
                                  error={this.state.errorPie}
                                  region={this.region}
                              />
                          </div>
                      </Col>
                      <Col xs={24} sm={24} md={24} lg={13} xl={14} xxl={14}>
                          <div className={'block' + ((this.state.darkMode) ? ' dark' : '')}>
                              <Row justify={'space-between'}>
                                  <Col xs={18} sm={19} md={20} lg={19} xl={20} xxl={21}>
                                      <Title className={'title' + ((this.state.darkMode) ? ' dark' : '')} level={4}>{(this.state.select === 'world') ? 'Monde' : this.state.select} Evolution</Title>
                                  </Col>
                                  <Col xs={6} sm={5} md={4} lg={5} xl={4} xxl={3}>
                                      <Switch
                                          defaultChecked={false}
                                          checked={this.state.switchChart}
                                          checkedChildren="Décès"
                                          unCheckedChildren="Confirmés"
                                          loading={this.state.isLoadingChart}
                                          onChange={(checked) => {this.toggleStatusLine(checked)}}
                                      />
                                  </Col>
                              </Row>
                              <LineWithLoader
                                  data={this.state.dataChart}
                                  region={this.region}
                                  dark={this.state.darkMode}
                                  isLoading={this.state.isLoadingChart}
                                  error={this.state.errorChart}
                              />
                          </div>
                      </Col>
                  </Row>
                  <Row gutter={[{ xs: 0, sm: 12, md: 12, lg: 12 }, 12]}>
                      <Col xs={24} sm={24} md={24} lg={14} xl={14} xxl={14}>
                          <div id={'map-block'} className={'block block-secondary' + ((this.state.darkMode) ? ' dark' : '')}>
                             <MapWithLoader
                                 data={this.state.dataMap}
                                 region={this.region}
                                 countriesWithRegions={this.countriesWithRegions}
                                 dark={this.state.darkMode}
                                 isLoading={this.state.isLoadingMap}
                                 error={this.state.errorMap}
                                 select={this.select}
                             />
                          </div>
                      </Col>
                      <Col xs={24} sm={24} md={24} lg={10} xl={10} xxl={10}>
                          <div className={'block' + ((this.state.darkMode) ? ' dark' : '')}>
                              <TableWithLoader
                                  data={this.state.dataTable}
                                  region={this.region}
                                  dark={this.state.darkMode}
                                  isLoading={this.state.isLoadingTable}
                                  error={this.state.errorTable}
                              />
                          </div>
                      </Col>
                  </Row>
                  <Row gutter={[{ xs: 0, sm: 12, md: 12, lg: 12 }, 12]}>
                      <Col xs={24} sm={24} md={24} lg={10} xl={10} xxl={10}>
                          <div className={'block' + ((this.state.darkMode) ? ' dark' : '')} style={{paddingRight:20,paddingLeft:20}}>
                              <Title className={'title' + ((this.state.darkMode) ? ' dark' : '')} level={4}>Top News</Title>
                              <div style={{height:478,overflow:'auto'}}>
                                  <NewsWithLoader
                                      data={this.state.news}
                                      dark={this.state.darkMode}
                                      isLoading={this.state.isLoadingNews}
                                      error={this.state.errorNews}
                                      itemLayout="horizontal"
                                      dataSource={this.state.news}
                                      renderItem={item => (
                                          <List.Item>
                                              <List.Item.Meta
                                                  className={'article' + ((this.state.darkMode) ? ' dark' : '')}
                                                  style={{textAlign:'left'}}
                                                  title={<a target="_blank" rel="noopener noreferrer" href={item.url}>{item.title}</a>}
                                                  description={item.source + ' - ' + moment(item.date).format('D MMM [à] k:mm')}
                                              />
                                          </List.Item>
                                      )}
                                      footer={<div style={{color: 'grey',textAlign:'right'}}>Fournit par <a target='_blank' rel="noopener noreferrer" href="https://azure.microsoft.com/fr-fr/services/cognitive-services/bing-news-search-api/">Bing News Search Api</a></div>}
                                  />
                              </div>
                          </div>
                      </Col>
                      <Col xs={24} sm={24} md={24} lg={14} xl={14} xxl={14}>
                          <div className={'block' + ((this.state.darkMode) ? ' dark' : '')} style={{paddingRight:20,paddingLeft:20}}>
                              <Row justify={'space-between'}>
                                  <Col xs={18} sm={19} md={20} lg={19} xl={20} xxl={21}>
                                      <Title className={'title' + ((this.state.darkMode) ? ' dark' : '')} level={4}>Cas Par Jour</Title>
                                  </Col>
                                  <Col xs={6} sm={5} md={4} lg={5} xl={4} xxl={3}>
                                      <Switch
                                          defaultChecked={false}
                                          checked={this.state.switchBar}
                                          checkedChildren="Décès"
                                          unCheckedChildren="Confirmés"
                                          loading={this.state.isLoadingBar}
                                          onChange={(checked) => {this.toggleStatusBar(checked)}}
                                      />
                                  </Col>
                              </Row>
                              <BarWithLoader
                                  data={this.state.dataBar}
                                  region={this.region}
                                  dark={this.state.darkMode}
                                  isLoading={this.state.isLoadingBar}
                                  error={this.state.errorBar}
                              />
                          </div>
                      </Col>
                  </Row>
                  <Footer className={'footer' + ((this.state.darkMode) ? ' dark' : '')}>
                      <Row gutter={[0, 24]}>
                          <Col style={{textAlign:'center'}} xs={24} sm={24} md={6} lg={6} xl={6} xxl={8}>
                              <span>Version de l'application :
                                  <strong> {config.version.toFixed(1)}</strong>
                              </span>
                          </Col>
                          <Col style={{textAlign:'center'}} xs={24} sm={24} md={12} lg={12} xl={12} xxl={8}>
                              <span>Réalisé par <a href="https://www.antonbourtnik.fr" target="_blank" rel="noopener noreferrer">
                                    <strong>Anton Bourtnik</strong>
                                    </a> - Tous droits réservés © {new Date().getFullYear()}
                              </span>
                              <div>Cette application utilise les données de
                                  <a href="https://api.covid19api.com" target="_blank" rel="noopener noreferrer"> api.covid19api.com </a> et
                                  <a href="https://covid19.mathdro.id/api" target="_blank" rel="noopener noreferrer"> covid19.mathdro.id</a>
                              </div>
                          </Col>
                          <Col style={{textAlign:'center'}} xs={24} sm={24} md={6} lg={6} xl={6} xxl={8}>
                              <span>
                                  <a href="mailto:contact@antonbourtnik.fr">
                                      <strong>Me contacter</strong>
                                  </a>
                              </span>&nbsp;<b style={{fontSize: 20}}>·</b>&nbsp;
                              <span>
                                  <a href="https://www.antonbourtnik.fr" target="_blank" rel="noopener noreferrer">Mentions Légales</a>
                              </span>
                          </Col>
                      </Row>
                  </Footer>
              </div>
          )
      }
}

export default App;
