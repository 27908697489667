import React, {Component} from 'react';
import {ResponsiveLine} from "@nivo/line";

import numeral from 'numeral'
import moment from 'moment'

class Line extends Component {

    format (tick) {
      return (tick <= 1000000 || tick % 1000000 === 0) ? numeral(tick).format('0a') : numeral(tick).format('0.0a')
    }

    getDaysInterval () {

        let width = window.innerWidth;
        let size = this.props.data.length;

        if (width > 1200) return Math.ceil(size/18);
        else if (width <= 992 && width >= 768) return Math.ceil(size/20);
        else if (width < 768 && width >= 400) return Math.ceil(size/12);
        else if (width < 400) return Math.ceil(size/9);
        else return Math.ceil(size/15);
    }

    render() {
            return (
                <ResponsiveLine
                    data={[{
                        id: "confirmed",
                        color: "hsl(136, 70%, 50%)",
                        data: this.props.data
                    }]}
                    margin={{ top: 3, right: 20, bottom: 70, left: 45 }}
                    xScale={{
                        type: 'time',
                        format: '%Y-%m-%d',
                        precision: 'day',
                    }}
                    groupMode={'stacked'}
                    xFormat="time:%Y-%m-%d"
                    yScale={{ type: 'linear', min: 'auto', max: 'auto', stacked: true, reverse: false }}
                    curve="natural"
                    theme={(this.props.dark) ? {axis:{ticks:{text:{fill:'#a1a8b8'}}}} : {}}
                    tooltip={value => (
                        <div style={{backgroundColor:'white', padding:5,border:'1px solid rgba(0,0,0,.2)',position:'absolute',right:0,width:220}}>
                            <b>{moment(value.point.data.xFormatted).format('DD MMM YYYY') + ' : '}</b> {numeral(value.point.data.y).format('0,0') + ' ' + value.point.data.label}
                        </div>
                    )}
                    axisTop={null}
                    axisRight={null}
                    axisBottom={{
                        orient: 'bottom',
                        tickSize: 5,
                        tickPadding: 5,
                        tickRotation: 0,
                        legend: '',
                        legendOffset: 36,
                        legendPosition: 'middle',
                        format: '%d/%m',
                        tickValues: [...Array(Math.round(this.props.data.length / this.getDaysInterval())).keys()].map(i => moment('2020-01-22').add( this.getDaysInterval() *i , 'days').toDate())
                    }}
                    axisLeft={{
                        orient: 'left',
                        tickSize: 5,
                        tickPadding: 5,
                        tickRotation: 0,
                        legend: '',
                        legendOffset: -40,
                        legendPosition: 'middle',
                        format: tick => (this.format(tick))
                    }}
                    colors={(this.props.dark) ? '#1B9F77' : '#1967D2'}
                    lineWidth={3}
                    enableArea={true}
                    enablePoints={false}
                    areaOpacity={0.3}
                    useMesh={true}
                />
            )
        }
}

export default Line;
